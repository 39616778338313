import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { HumidityData } from 'src/views/objects/Balancing/BalancingView';

export const useHumidity = (objectId: number, controlStateIds?: number[]) => {
    const [humidityCache, setHumidityCache] = useState<
        Record<number, HumidityData[]>
    >({});
    // state with data
    const [data, setData] = useState<HumidityData[]>([]);
    // state with route

    // use effect when route changes

    useEffect(() => {
        if (controlStateIds === undefined || controlStateIds.length === 0) {
            return;
        }

        const stateId: number = parseInt(controlStateIds.join(''), 10);
        if (stateId in humidityCache) {
            setData((humidityCache as Record<number, HumidityData[]>)[stateId]);
        } else {
            (async () => {
                if (objectId !== undefined) {
                    new API()
                        .fetchHumidityData(objectId, controlStateIds)
                        .then((humiditydata) => {
                            const { humidity } = humiditydata;
                            if (humiditydata) {
                                setHumidityCache({
                                    ...humidity,
                                    [stateId]: humidity.data,
                                });
                                setData(humidity.data);
                            }
                        });
                }
            })();
        }
    }, [humidityCache, controlStateIds, objectId]);

    return { data };
};
