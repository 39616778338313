import House3D from 'src/components/House3D/House3D';
import { GradientKeyViewThermometer } from 'src/views/objects/GradientKeyHumidity';
import { GradientKey } from 'src/models/GradientKey';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';
import { GradientConclusionView } from '../GradientConclusion';
import { Tooltip } from 'antd';

export interface HumidityData {
    sensorFunctionType: number;
    sensorFunctionTypeIndex: number;
    absoluteHumidity: number;
    controlState: number;
}

interface BalancingVisualizationProps {
    setTableView: any;
    sensorIds: number[];
    objectId: number;
    setSensorIds: (ids: number[]) => void;
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    transformedData?: any[];
    controlStateId: number;
    threshold: number;
    gradientKey: GradientKey;
    humidityData: HumidityData[];
    humidityLevel: number;
    realtimeRawTemperatures?: any[];
    viewMode: string[];
    data: V3D_Object;
    error: any;
}

function BalancingVisualization(props: BalancingVisualizationProps) {
    const {
        controlStateId,
        objectId,
        referenceTemperature,
        sensorIds,
        setSensorIds,
        setTableView,
        transformedData,
        threshold,
        gradientKey,
        humidityData,
        humidityLevel,
        realtimeRawTemperatures,
        viewMode,
        data,
        error,
    } = props;

    const isHumidity =
        !!viewMode &&
        viewMode.length === 1 &&
        viewMode.some((option) => option.toLowerCase() === 'humidity');

    const dataTransformed = (realtimeRawTemperatures || []).map(
        (item, index) => {
            const temperatureValues = item.data
                ? item.data.map((entry: any) => entry.value)
                : [];

            const deltaAverage = temperatureValues.length
                ? (
                      temperatureValues.reduce(
                          (sum: number, temp: number) =>
                              Number(sum) + Number(temp),
                          0,
                      ) / temperatureValues.length
                  ).toFixed(1)
                : undefined;

            return {
                key: `sensor-${item.id}-${item.objectId}`,
                id: item.id,
                isActive: item.isActive,
                name: item.name || item.name.substring(0, 10),
                title: item.name || item.name.substring(0, 10),
                deltaAverage,
                sensorFunctionTypeId: item.sensorFunctionTypeId,
                sensorFunctionTypeIndex: item.sensorFunctionTypeIndex,
            };
        },
    );

    const getAverageForSensors = (sensors: any[]) => {
        const deltaAverages: any = [];
        for (let sensor of sensors) {
            const currDeltaAvg = sensor.deltaAverage;
            if (currDeltaAvg && !isNaN(Number(currDeltaAvg))) {
                deltaAverages.push(currDeltaAvg);
            }
        }

        const deltaAverage = deltaAverages.length
            ? (
                  deltaAverages.reduce(
                      (sum: number, temp: number) => Number(sum) + Number(temp),
                      0,
                  ) / deltaAverages.length
              ).toFixed(1)
            : undefined;

        return deltaAverage;
    };

    const average = getAverageForSensors(dataTransformed);

    return (
        <div className="h-full">
            <House3D
                onNavigateToTable={() => setTableView('table')}
                selectedSensors={sensorIds}
                objectId={objectId}
                onSensorSelected={(id) => {
                    setSensorIds([...sensorIds, id]);
                }}
                referenceTemperature={referenceTemperature}
                sensors={transformedData || []}
                onSensorDeselected={(id) => {
                    setSensorIds([
                        ...sensorIds.filter((sensorId) => sensorId !== id),
                    ]);
                }}
                stateId={controlStateId}
                tolerance={threshold}
                gradientKey={gradientKey}
                humidityData={humidityData}
                humidityLevel={humidityLevel}
                viewMode={viewMode}
                data={data}
                error={error}
            />
            {viewMode &&
            viewMode.some((option) => option.toLowerCase() === 'conclusion') ? (
                <div
                    className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                    }}
                >
                    <GradientConclusionView />
                </div>
            ) : (
                <div
                    className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                        marginLeft: '10px',
                    }}
                >
                    <GradientKeyViewThermometer
                        unit={isHumidity ? undefined : '°C'}
                        gradientKey={gradientKey}
                    />
                </div>
            )}

            {average && (
                <div
                    className="absolute top-0 right-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                        marginRight: '10px',
                        fontSize: '20px',
                    }}
                >
                    <Tooltip title={'Medeltemperatur för perioden'}>
                        {average}°C
                    </Tooltip>
                </div>
            )}
        </div>
    );
}

export default BalancingVisualization;
