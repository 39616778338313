import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { TemperatureWithControlState } from 'src/models/Temperature';
import { selectRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import { useSelector } from 'react-redux';

export interface SensorWithAverage extends TemperatureWithControlState {
    sensorName: string;
    sensorId: number;
    underlyingSensorId: number;
}

export const useSensorAverageWithControlStates = (
    objectId: number,
    stateIds: number[],
) => {
    const api = new API();
    const selectedRealtimeSensors = useSelector(selectRealtimeSensors);
    const [sensors, setSensorAverages] = useState<SensorWithAverage[]>([]);

    useEffect(() => {
        if (selectedRealtimeSensors && selectedRealtimeSensors.length) {
            const body = { sensors: selectedRealtimeSensors };
            const promise = api.fetchMultipleSensorsWithAvgOfMultipleControlStates(
                objectId,
                stateIds,
                body,
            );

            promise
                .then((sensorsAverages) => {
                    setSensorAverages(sensorsAverages);
                })
                .catch((error) => {
                    console.error('Error fetching sensor averages:', error);
                });
        }
        // eslint-disable-next-line
    }, [selectedRealtimeSensors, stateIds]);

    return { sensors, selectedRealtimeSensors };
};
