import { DatePicker, Button } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import useViewport from 'src/hooks/generic/useViewport';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import { setRefreshFlag } from 'src/store/reducers/selectedObjectSlice';
import { ControlState } from 'src/models/ControlState';
import './RangePickerRealTime.scss';

interface DatePickerProps {
    period: { start: string; stop: string };
    onChange: (value: { start: string; stop: string }) => void;
    setHotAndColdState: (value: boolean) => void;
    fetchHotAndColdAgain: () => void;
    clearSensors: () => void;
    controlStates: ControlState[];
}

const RangePickerBalance = (props: DatePickerProps) => {
    const {
        period,
        onChange,
        clearSensors,
        setHotAndColdState,
        fetchHotAndColdAgain,
        controlStates,
    } = props;

    const dispatch = useDispatch();
    const { mode } = useViewport();
    const viewType = useSelector(
        (state: RootState) => state.datePickerBalance.viewType,
    );

    const [startDate, setStartDate] = useState<string | undefined>(
        period.start,
    );

    const { t } = useTranslation();

    const handleArrowClickCalled = useRef<boolean>(false);

    const [stopDate, setStopDate] = useState<string | undefined>(period.stop);
    const [endOpen, setEndOpen] = useState(false);
    const dateFormat = 'YYYY-MM-DD';

    const disabledStartDate = (startDate: Moment) => {
        if (!startDate || !controlStates.length) return false;

        const maxDate = moment(
            controlStates[controlStates.length - 1].time_period_start,
            dateFormat,
        );
        const endValue = moment(startDate, dateFormat);

        const minDate = new Date(controlStates[0].time_period_start);

        return (
            startDate.isBefore(minDate) ||
            startDate.isAfter(maxDate) ||
            startDate.valueOf() > endValue.valueOf()
        );
    };

    const disabledEndDate = (endDate: Moment) => {
        if (!endDate || !controlStates.length) return false;

        const minDate = moment(controlStates[0].time_period_start, dateFormat);
        const maxDate = moment(
            controlStates[controlStates.length - 1].time_period_start,
            dateFormat,
        );
        const startValue = moment(startDate, dateFormat);

        if (!startValue) return false;

        const isBeforeStartDate = endDate.valueOf() < startValue.valueOf();
        const isMoreThanOneMonthAfterStart =
            endDate.valueOf() > startValue.add(1, 'month').valueOf();
        const isOutOfRange =
            endDate.isBefore(minDate) || endDate.isAfter(maxDate);

        return (
            isBeforeStartDate || isMoreThanOneMonthAfterStart || isOutOfRange
        );
    };

    const handleStartOpenChange = (open: boolean) => {
        if (!open) {
            setEndOpen(true);
        }
    };

    const handleEndOpenChange = (open: boolean) => {
        setEndOpen(open);
    };

    const handleArrowClick = (direction: 'forward' | 'backward') => {
        const increment = direction === 'forward' ? 1 : -1;

        if (viewType === 'day') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'day')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'day')
                    .format(dateFormat),
            );
        } else if (viewType === 'week') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'week')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'week')
                    .format(dateFormat),
            );
        } else if (viewType === 'month') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'month')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'month')
                    .format(dateFormat),
            );
        } else if (viewType === 'year') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'year')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'year')
                    .format(dateFormat),
            );
        }

        handleArrowClickCalled.current = true;
    };

    const activateHotAndCold = () => {
        dispatch(setRefreshFlag(true));
        setHotAndColdState(true);
        fetchHotAndColdAgain();
    };

    const controlStartDate = new Date(controlStates[0].time_period_start);
    const startDateObj = new Date(startDate!);

    useEffect(() => {
        if (
            handleArrowClickCalled.current &&
            startDate !== undefined &&
            stopDate !== undefined
        ) {
            onChange({ start: startDate, stop: stopDate });
            handleArrowClickCalled.current = false;
        }
    }, [startDate, stopDate, onChange]);

    return (
        <div
            className={classNames({
                flex: true,
                'items-center': true,
                'flex-wrap': true,
                'gap-y-3': mode !== 'mobile',
                'pb-3': mode !== 'mobile',
            })}
        >
            <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => handleArrowClick('backward')}
                disabled={startDateObj <= controlStartDate}
            />
            <DatePicker
                disabledDate={disabledStartDate}
                className="datepicker-mobile"
                format="YYYY-MM-DD"
                value={moment(startDate, dateFormat)}
                placeholder="Start"
                onBlur={(v: any) => {
                    if (startDate !== period.start && stopDate && startDate) {
                        onChange({ start: startDate, stop: stopDate });
                    }
                }}
                onChange={(v: any) => {
                    setStartDate(v.format(dateFormat));
                    setStopDate(v.add(1, viewType).format(dateFormat));
                }}
                onOpenChange={handleStartOpenChange}
            />

            <DatePicker
                disabledDate={disabledEndDate}
                className="datepicker-mobile"
                format="YYYY-MM-DD"
                value={moment(stopDate, dateFormat)}
                placeholder="End"
                onSelect={() => {
                    if (stopDate !== period.stop && stopDate && startDate) {
                        onChange({ start: startDate, stop: stopDate });
                    }
                }}
                onChange={async (v: any) => {
                    setStopDate(v.format(dateFormat));
                    if (startDate && stopDate) {
                        onChange({
                            start: startDate,
                            stop: v.format(dateFormat),
                        });
                    }
                }}
                onBlur={(v: any) => {
                    if (stopDate !== period.stop && stopDate && startDate) {
                        onChange({ start: startDate, stop: stopDate });
                    }
                }}
                defaultOpen={false}
                open={endOpen}
                onOpenChange={handleEndOpenChange}
            />
            <Button
                disabled={new Date(stopDate!) >= new Date()}
                icon={<ArrowRightOutlined />}
                onClick={() => handleArrowClick('forward')}
            />

            <div
                style={{
                    marginLeft: '10px',
                    marginBottom: mode === 'mobile' ? '10px' : 0,
                }}
            >
                <Button type="default" onClick={activateHotAndCold}>
                    {t('Hot & Cold')}
                </Button>

                <Button type="default" onClick={clearSensors}>
                    {t('Clear')}
                </Button>
            </div>
        </div>
    );
};

export default RangePickerBalance;
