import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { TemperatureWithControlState } from 'src/models/Temperature';

export const useAverageTemperaturesWithAllControlStates = (
    id: number,
    controlStateIds: number[],
) => {
    const [cache, setCache] = useState<
        Record<number, TemperatureWithControlState>
    >({});
    const [data, setData] = useState<TemperatureWithControlState>();

    useEffect(() => {
        if (controlStateIds === undefined || controlStateIds.length === 0) {
            return;
        }

        const stateId: number = parseInt(controlStateIds.join(''), 10);
        if (stateId in cache) {
            setData(cache[stateId]);
        } else {
            (async () => {
                if (id && controlStateIds.length !== 0) {
                    const [currentData] = await Promise.all([
                        new API().fetchAverageTempertureWithAllControlStates(
                            id,
                            controlStateIds,
                        ),
                    ]);

                    const cached = { ...cache, [stateId]: currentData };

                    setCache(cached);
                    setData(currentData);
                }
            })();
        }
    }, [cache, controlStateIds, id]);

    return { data };
};
