import { Empty, Typography } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scaleTime, scaleLinear } from 'd3-scale';
import {
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    Tooltip,
    ReferenceArea,
} from 'recharts';
//import useViewHeight from 'src/hooks/generic/useViewHeight';
import { RealTimeResponse } from 'src/hooks/objects/useRealTime';
import { Sensor } from 'src/models/Sensor';
import useViewport from 'src/hooks/generic/useViewport';
// import {
//     SensorWithTemperature,
//     TemperatureMapping,
// } from 'src/models/Temperature';
// import {
//     generateGradientKey,
//     getGradientColorForTemperature,
//     gradients,
// } from 'src/utils/gradients';
// // import { SensorWithAverage } from 'src/hooks/objects/useSensorAvergage';
import { minMaxArrValue } from 'src/utils/MinMax';

interface GraphProps {
    hotNCold: boolean;
    start?: string;
    stop?: string;
    data?: RealTimeResponse[];
    data1?: RealTimeResponse[];
    data2?: RealTimeResponse[];
    data3?: RealTimeResponse[];
    data4?: RealTimeResponse[];
    data5?: RealTimeResponse[];
    data6?: RealTimeResponse[];
    include?: number[];
    newTimestamps: number[];
    secondAxisColor?: string;
    firstAxisColor?: string;
    left: number | string;
    right: number | string;
    refLeft: number;
    refRight: number;
    // sensorAverages: SensorWithTemperature[];
    // prevSensorAverages?: SensorWithTemperature[];
    // averageTemperatures: TemperatureMapping[];
    // prevAverageTemperatures: TemperatureMapping[];
    // referenceTemperature: number;
    zoom: (graphIndex: number) => void;
    onMouseDown: (e: any) => void;
    onMouseMove: (e: any) => void;
    onReset: () => void;
}

interface SensorDataEntry {
    timestamp: number;
    [sensorId: number]: number;
}

interface GroupedData {
    [sensorId: number]: SensorDataEntry[];
}

interface CustomizedDotProps {
    cx?: number;
    cy?: number;
    stroke?: string;
    payload?: any;
    value?: number;
    index?: number;
    closestDataPoint?: any;
    id: number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: number;
}

export function RealTimeGraphHeating(props: GraphProps) {
    const myRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<HTMLDivElement>(null);
    //const { screen } = useViewHeight();
    const { mode } = useViewport();

    const [closestDataPoint, setClosestDataPoint] = useState<any>(null);

    const {
        refRight,
        refLeft,
        data,
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        //secondAxisColor,
        //firstAxisColor,
        onMouseDown,
        include,
        // sensorAverages,
        // prevSensorAverages,
        // averageTemperatures,
        // prevAverageTemperatures,
        // referenceTemperature,
        newTimestamps,
    } = props;

    // const sensorIdsToAverages: Record<number, TemperatureMapping[]> = {};
    // const averages =
    //     sensorAverages.flatMap((sa) => sa.data).length > 0
    //         ? sensorAverages
    //         : prevSensorAverages ?? [];

    // for (const avg of averages) {
    //     Object.assign(sensorIdsToAverages, { [avg.id]: avg.data });
    // }

    const { t } = useTranslation();

    const groupDataBySensorId = (data: SensorDataEntry[]): GroupedData => {
        const groupedData: GroupedData = {};

        data.forEach((entry) => {
            const { timestamp, ...rest } = entry;
            Object.entries(rest).forEach(([sensorId, value]) => {
                const parsedSensorId = parseInt(sensorId, 10);
                if (!groupedData[parsedSensorId]) {
                    groupedData[parsedSensorId] = [
                        { timestamp, [parsedSensorId]: value },
                    ];
                } else {
                    groupedData[parsedSensorId].push({
                        timestamp,
                        [parsedSensorId]: value,
                    });
                }
            });
        });

        return groupedData;
    };
    const getData = (): any => {
        const arr: {
            [x: number]: number;
            timestamp: number;
            name: string | null;
            value: number;
            id: number;
            color: string;
        }[] = [];

        const exists = (timestamp: number) => {
            return (
                arr.findIndex((v) => {
                    return v.timestamp === timestamp;
                }) !== -1
            );
        };

        const sensorMap: { [x: number]: Sensor } = {};

        data?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);

                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });

        data1?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);

                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });

        data2?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);

                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });
        data3?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);
                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });

        data4?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);
                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });

        data5?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);
                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });

        data6?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            const color = colorPicker(sensor);
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);
                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                            name: sensor.name,
                            id: sensor.id,
                            value: Math.round(v.value * 10) / 10,
                            color,
                        });
                    }
            });
        });

        let parsedData = arr.sort((c, p) => c.timestamp - p.timestamp);
        const groupedData = groupDataBySensorId(parsedData);

        if (parsedData && parsedData.length) {
            parsedData = parsedData.filter(
                (item: any) =>
                    item.timestamp <= newTimestamps[newTimestamps.length - 1],
            );

            // we don't need to add pseudo entry points
            // timestamps.forEach((chosenTimestamp: any) => {
            //     Object.keys(groupedData).forEach((sensorId) => {
            //         const dataEntries = groupedData[parseInt(sensorId, 10)];
            //         let closestTimestamp = dataEntries[0].timestamp; // Initialize with the first timestamp
            //         let minTimeDiff = Math.abs(
            //             chosenTimestamp - closestTimestamp,
            //         );

            //         let lastentry: any = null;

            //         dataEntries.forEach((entry) => {
            //             const timeDiff = Math.abs(
            //                 chosenTimestamp - entry.timestamp,
            //             );
            //             if (timeDiff < minTimeDiff) {
            //                 minTimeDiff = timeDiff;
            //                 closestTimestamp = entry.timestamp;
            //                 lastentry = entry;
            //             }
            //         });

            //         const newEntry = {
            //             ...lastentry,
            //             timestamp: chosenTimestamp,
            //         };

            //         parsedData.push(newEntry);
            //     });
            // });
            parsedData = parsedData.sort((c, p) => c.timestamp - p.timestamp);
        }

        return {
            parsedData,
            timestamps: newTimestamps,
            groupedData,
            sensorMap,
        };
    };

    function formatTimestamp(label: number): string {
        const date = new Date(label * 1000);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const hours = date.getHours();
        const minutes = '0' + date.getMinutes();
        const stringHours =
            hours > 9 ? hours.toString() : '0' + hours.toString();

        return `${day} ${month}, ${stringHours}:${minutes.slice(-2)}`;
    }

    const CustomTooltip: React.FC<CustomTooltipProps> = ({
        active,
        payload,
        label,
    }) => {
        if (active && payload && payload.length && closestDataPoint) {
            const { unit } = payload[0];
            const { name, timestamp, value, color }: any = closestDataPoint;

            return (
                <div
                    className="custom-tooltip"
                    style={{
                        border: '1px solid rgb(204, 204, 204)',
                        background: '#fff',
                        padding: '10px',
                    }}
                >
                    {label ? (
                        <div>{`${formatTimestamp(timestamp)}`}</div>
                    ) : null}
                    <div
                        style={{
                            color,
                        }}
                    >
                        {`${name} : ${value?.toFixed(1)}${unit}`}
                    </div>
                </div>
            );
        }

        return null;
    };

    const CustomizedDot: React.FC<CustomizedDotProps> = (props) => {
        const { cx, cy, stroke, payload, closestDataPoint, id } = props;

        // Check if this dot is the closest data point
        if (
            cx &&
            cy &&
            closestDataPoint &&
            payload.timestamp === closestDataPoint.timestamp &&
            closestDataPoint.id === id
        ) {
            return (
                <circle cx={cx} cy={cy} r={4} stroke={stroke} fill={stroke} />
            );
        }
        return null;
    };

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    //eslint-disable-next-line
    const { parsedData, groupedData, sensorMap } = getData();
    const hasData = parsedData.length > 0;

    function colorPicker(sensor: Sensor) {
        const type = sensor.sensorFunctionTypeId;

        // const colors = [
        //     '#faad14',
        //     '#52c41a',
        //     '#eb2f96',
        //     '#722ed1',
        //     '#389e0d',
        //     '#f5222d',
        //     '#ad8b00',
        //     '#873800',
        //     '#90a729',
        //     '#135200',
        // ];

        // const hot = ['#5c0011', '#a8071a', '#f5222d', '#ff7875', '#ffccc7'];
        // const cold = ['#bae7ff', '#69c0ff', '#1890ff', '#003a8c', '#002766'];

        if (type === 0) {
            return '#91d5ff';
        }
        if (type === 1) {
            return '#003a8c';
        }
        if (type === 21) {
            return '#faad14';
        }
        if (type === 11) {
            return '#fa541c';
        }
        if (type === 61) {
            return '#820014';
        }

        if (type === 51) {
            return '#D98880';
        }
        // if (props.hotNCold) {
        //     if (index < 5) {
        //         index = index + 1;
        //         return hot[h++];
        //     } else {
        //         index = index + 1;
        //         return cold[c++];
        //     }
        // } else {

        // const avg =
        //     0.1 *
        //     Math.round(
        //         10 *
        //             (averageTemperatures.find(
        //                 (e) => e.fromTemperature === referenceTemperature,
        //             )?.toTemperature ?? 21),
        //     );

        // const t = getGradientColorForTemperature(
        //     sensorIdsToAverages[sensor.id] ?? [],
        //     averageTemperatures.length > 0
        //         ? averageTemperatures
        //         : prevAverageTemperatures,
        //     referenceTemperature,
        //     generateGradientKey(
        //         { high: avg + 1, low: avg - 1 },
        //         gradients.thermometer.mix,
        //     ),
        //     1,
        // );

        return t ?? '#646464';
        // }
    }

    const handleMouseMove = (event: any) => {
        if (event.isTooltipActive) {
            // const { activeTooltipIndex, activeLabel } = event;
            // const closestData = realtimeData[activeTooltipIndex];

            if (!parsedData || (parsedData && parsedData.length === 0)) {
                return;
            }

            const chartElement = chartRef.current;

            const chartWidth = chartElement
                ? chartElement.getBoundingClientRect().width
                : 0;
            //eslint-disable-next-line
            let chartHeight = 180; //default

            const cartesianGrid = chartElement?.querySelector(
                '.recharts-cartesian-grid',
            );
            if (cartesianGrid) {
                chartHeight = cartesianGrid.getBoundingClientRect().height;
            }

            const { chartX, chartY } = event;

            let closestDataPoint: any = null;
            let minDistance = Infinity;

            const domainToDetermine = minMaxArrValue(5, [
                data || [],
                data1 || [],
                data2 || [],
                data3 || [],
                data5 || [],
                data6 || [],
            ]);

            const xPaddingLeft = 15;
            const xPaddingRight = 0;
            const yPaddingBottom = 20;

            const newYscaleToDetermine = scaleLinear()
                .domain(domainToDetermine)
                .range([chartHeight - yPaddingBottom, 0]);

            const firstDate = new Date(newTimestamps[0] * 1000);

            const lastDate = new Date(
                newTimestamps[newTimestamps.length - 1] * 1000,
            );

            const xScaleToDetermine = scaleTime()
                .domain([new Date(firstDate), new Date(lastDate)])
                .range([xPaddingLeft, chartWidth - xPaddingRight]);

            parsedData.forEach((dataPoint: any) => {
                // Calculate the X and Y positions of the data point
                const dataPointX = xScaleToDetermine(
                    new Date(dataPoint.timestamp * 1000),
                );
                const dataPointY = newYscaleToDetermine(dataPoint.value);

                // Calculate the distance from the mouse position to the data point
                // Euclidean distance
                const distance = Math.sqrt(
                    Math.pow(chartX - dataPointX, 2) +
                        Math.pow(chartY - dataPointY, 2),
                );

                if (distance < minDistance) {
                    minDistance = distance;
                    closestDataPoint = dataPoint;
                }
            });

            if (closestDataPoint) {
                const index = parsedData.findIndex(
                    (point: any) => point.id === closestDataPoint.id,
                );
                closestDataPoint.index = index;
            }

            console.log('setting closest data point');

            setClosestDataPoint(closestDataPoint);
        }
    };

    const handleMouseUp = () => {
        //remove closestdatapoint
        if (mode !== 'mobile') {
            setClosestDataPoint(null);
        }
    };

    const labelFormatter = (label: number) => formatTimestamp(label);
    const valueFormatter = (value: any) => `${value.toFixed(1)}`;

    const handleTouchMove = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('handle touch move', e);

        const touch = e.touches[0];

        const chartRect = myRef.current?.getBoundingClientRect();

        if (!chartRect || !touch) return;

        if ('clientX' in touch && 'clientY' in touch) {
            // Calculate chartX and chartY relative to the chart's top-left corner
            const chartX = touch.clientX - chartRect.left;
            const chartY = touch.clientY - chartRect.top;

            const customMouseEvent = {
                chartX,
                chartY,
                isMobile: true,
            };

            handleMouseMove(customMouseEvent);
        }
    };

    const handleMouseLeave = (e: any) => {
        setClosestDataPoint(null);
    };

    return (
        <>
            <div ref={myRef} style={{ width: '100%' }}>
                <div style={{ marginTop: '20px', width: '100%' }}>
                    {!hasData && <Empty description={t('No data')} />}
                    {hasData && (
                        <div className="flex flex-wrap justify-between items-center">
                            <Typography.Title level={5}>
                                {t('Värmesystem och utomhustemperatur')}
                            </Typography.Title>
                        </div>
                    )}
                    {hasData && (
                        <div ref={chartRef} onTouchStart={handleTouchMove}>
                            <ResponsiveContainer height={300} width="100%">
                                <LineChart
                                    onMouseDown={onMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onMouseLeave={handleMouseLeave}
                                    data={parsedData}
                                    margin={{
                                        top: 0,
                                        // right: mode === 'desktop' ? 35 : 0,
                                        // left: mode === 'desktop' ? 20 : 0,
                                        bottom: 50,
                                    }}
                                >
                                    <defs>
                                        <linearGradient
                                            id="#faad14"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="50%"
                                                stopColor="#faad14"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#fa541c"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="50%"
                                                stopColor="#fa541c"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#91d5ff"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="50%"
                                                stopColor="#91d5ff"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#003a8c"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#003a8c"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#820014"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="50%"
                                                stopColor="#820014"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#52c41a"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#52c41a"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#eb2f96"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#eb2f96"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#722ed1"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#722ed1"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#389e0d"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#389e0d"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#f5222d"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#f5222d"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#faad14"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#faad14"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="grey"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#8c8c8c"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#ad8b00"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#ad8b00"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#873800"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#873800"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#90a729"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#90a729"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="#135200"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="10%"
                                                stopColor="#135200"
                                                stopOpacity={0.2}
                                            />
                                            <stop
                                                offset="90%"
                                                stopColor="#FFFFFF"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="5 5" />

                                    <XAxis
                                        type="number"
                                        domain={[
                                            newTimestamps[0],
                                            newTimestamps[
                                                newTimestamps.length - 1
                                            ],
                                        ]}
                                        scale="time"
                                        tickFormatter={(v) => {
                                            const date = new Date(v * 1000);
                                            const day = date.getDate();
                                            const month =
                                                monthNames[date.getMonth()];
                                            const formattedHour =
                                                date.getHours() +
                                                ':' +
                                                (
                                                    '0' + date.getMinutes()
                                                ).substr(-2);

                                            return (
                                                day +
                                                month +
                                                ' ' +
                                                formattedHour
                                            );
                                        }}
                                        ticks={newTimestamps}
                                        dataKey="timestamp"
                                        angle={-45}
                                        interval={'preserveStartEnd'}
                                        allowDataOverflow={false}
                                        padding={{ left: 15 }}
                                    />

                                    <YAxis
                                        padding={{ bottom: 20 }}
                                        mirror={true}
                                        yAxisId="left"
                                        orientation="left"
                                        allowDataOverflow={true}
                                        unit="°C"
                                        // stroke={
                                        //     firstAxisColor
                                        //         ? firstAxisColor
                                        //         : undefined
                                        // }
                                        tickCount={10}
                                        //domain={minMaxValueWhole(data, 5, data1)}
                                        domain={minMaxArrValue(5, [
                                            data || [],
                                            data1 || [],
                                            data2 || [],
                                            data3 || [],
                                            data5 || [],
                                            data6 || [],
                                        ])}
                                        tickFormatter={(v) => v.toFixed(0)}
                                        type="number"
                                    />

                                    <YAxis
                                        padding={{ bottom: 20 }}
                                        mirror={true}
                                        yAxisId="right"
                                        orientation="right"
                                        allowDataOverflow={true}
                                        unit="°C"
                                        tickCount={10}
                                        domain={minMaxArrValue(5, [
                                            data || [],
                                            data1 || [],
                                            data2 || [],
                                            data3 || [],
                                            data5 || [],
                                            data6 || [],
                                        ])}
                                        tickFormatter={(v) => v.toFixed(0)}
                                        type="number"
                                        // stroke={
                                        //     secondAxisColor
                                        //         ? secondAxisColor
                                        //         : undefined
                                        // }
                                    />

                                    <Tooltip
                                        content={<CustomTooltip />}
                                        formatter={valueFormatter}
                                        labelFormatter={labelFormatter}
                                    />
                                    {data?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    yAxisId="left"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    strokeWidth={3}
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="left"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : (
                                                // This is here as a mechanism for keep the colors from the control in sync with the graph since the controller update the props of this but not its own internal state notice dataKey prop diffrence
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="left"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={-1}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                    })}
                                    {data1?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="left"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="left"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : null;
                                    })}
                                    {data2?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    color={colorPicker(v)}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : null;
                                    })}
                                    {data3?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : null;
                                    })}
                                    {data4?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : null;
                                    })}
                                    {data5?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : null;
                                    })}
                                    {data6?.map((v, i) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                            )}
                                                            id={v.id}
                                                            closestDataPoint={
                                                                closestDataPoint
                                                            }
                                                        />
                                                    }
                                                    activeDot={false}
                                                />
                                            ) : null;
                                    })}
                                    {refLeft && refRight ? (
                                        <ReferenceArea
                                            yAxisId="left"
                                            x1={refLeft}
                                            x2={refRight}
                                            strokeOpacity={0.3}
                                        />
                                    ) : null}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
