import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import {
    startLoading,
    setData,
    setError,
} from 'src/store/reducers/visualizationSlice';
import { API } from 'src/api/API';

const use3DDataWithControlStates = () => {
    const dispatch = useDispatch();

    // Access the cache, isLoading, and error from the Redux store
    const { cache, isLoading, error } = useSelector(
        (state: RootState) => state.visualization,
    );

    const fetchData = useCallback(
        (objectId: number, stateIds: number[]) => {
            const stateId: number = parseInt(stateIds.join(''), 10);

            const data = cache[`${objectId}:${stateId}`];
            if (data !== undefined) return; // Avoid fetching if data exists

            dispatch(startLoading());

            new API()
                .fetchObjectVisualizationWithControlStates(objectId, stateIds)
                .then((data: any) => {
                    dispatch(setData({ objectId, stateId, data }));
                })
                .catch((e) => {
                    dispatch(setError(e.message));
                });
        },
        [dispatch, cache],
    );

    return { fetchData, error, isLoading };
};

export default use3DDataWithControlStates;
