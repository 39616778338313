import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { TemperatureWithControlState } from 'src/models/Temperature';

export const useAverageTemperaturesWithControlState = (
    id: number,
    controlState?: number,
    fetchPrevious?: boolean,
) => {
    const [cache, setCache] = useState<
        Record<number, TemperatureWithControlState>
    >({});
    const [data, setData] = useState<TemperatureWithControlState>();
    const [previous, setPrevious] = useState<TemperatureWithControlState>();

    const [controlStateId, setControlStateId] = useState(controlState);

    useEffect(() => {
        if (controlState === undefined) {
            return;
        }

        if (controlState in cache) {
            setData(cache[controlState]);
            if (fetchPrevious && controlState > 0) {
                setPrevious(cache[controlState - 1]);
            }
            return;
        }

        (async () => {
            if (id && controlState !== undefined) {
                const api = new API();

                const [currentData, prevData] = await Promise.all([
                    api.fetchAverageTempertureWithControlState(
                        id,
                        controlState,
                    ),
                    fetchPrevious && controlState > 0
                        ? api.fetchAverageTempertureWithControlState(
                              id,
                              controlState - 1,
                          )
                        : Promise.resolve(undefined),
                ]);

                setCache((prevCache) => ({
                    ...prevCache,
                    [controlState]: currentData,
                    ...(fetchPrevious && prevData
                        ? { [controlState - 1]: prevData }
                        : {}),
                }));

                setData(currentData);
                setPrevious(prevData);
            }
        })();
    }, [cache, controlState, controlStateId, id, fetchPrevious]);

    return { data, previous, setAvgControlState: setControlStateId };
};
